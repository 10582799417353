import React, { FC, memo } from "react";
import { Button } from "antd";
import {
  CloseOutlined,
  PictureOutlined,
  VideoCameraOutlined,
  AudioOutlined,
} from "@ant-design/icons";
import { TExpectedUserInput } from "..";
import "./styles.css";

interface IProps {
  fileList: Array<any> | undefined | any;
  setFileList: Function;
  expectedUserInput: TExpectedUserInput;
}
const LocaleAttachmentsMap: FC<IProps> = ({
  fileList,
  setFileList,
  expectedUserInput
} ) => {
  const IsImage = (url: string) => {
    const fileTypes: any[] = ["png", "jpg", "jpeg"];
    const fileExtension = url?.split(".").pop();
    if (fileTypes.includes(fileExtension?.toLowerCase())) return true;
    return false;
  };

  const IsVideo = (url: string) => {
    const fileTypes: any[] = ["mp4", "avi", "flv", "wmv", "mov", "hvec"];
    const fileExtension = url?.split(".").pop();
    if (fileTypes.includes(fileExtension?.toLowerCase())) return true;
    return false;
  };

  const IsAudio = (url: string) => {
    const fileTypes: any[] = [
      "mp3",
      "ogg",
      "wav",
      "wave",
      "wma",
      "oga",
      "mogg",
      "aac",
      "m4a",
      "3gp",
      "m4r",
    ];
    const fileExtension = url?.split(".").pop();
    if (fileTypes.includes(fileExtension?.toLowerCase())) return true;
    return false;
  };

  return (
    <div className="attachments-wrapper">
      <p>Archivos adjuntos:</p>
      <div className="attached-representation-container">
        {fileList.map((file: any) => (
          <div
            className="attachment-item"
          >
            {IsImage(file.name) && <PictureOutlined />}
            {IsAudio(file.name) && <AudioOutlined />}
            {IsVideo(file.name) && <VideoCameraOutlined />}
            <p>{file.name}</p>
            {expectedUserInput === "message" && <Button
              type="ghost"
              icon={<CloseOutlined />}
              disabled={expectedUserInput !== "message"}
              onClick={() => {
                setFileList(fileList.filter((f: any) => f.uid !== file.uid));
              }}
            />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(LocaleAttachmentsMap);
