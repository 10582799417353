import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { HelmetProvider } from "react-helmet-async";
import "moment/locale/es-mx";
import locale from "antd/lib/locale/es_ES";

// CSS Code
import "./assets/Fonts/em-print/styles.css";
import "antd/dist/antd.css";
import "./CkUI/styles/variables.css";
import "./theme/theme.css";

import store from "./stores";
import { AppEndConsumer } from "./AppEndConsumer";

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ConfigProvider locale={locale}>
      <HelmetProvider>
        <BrowserRouter>
          <AppEndConsumer />
        </BrowserRouter>
      </HelmetProvider>
    </ConfigProvider>
  </Provider>
);
