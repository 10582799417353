import { Modal } from "antd";
import React, { useRef } from "react";
import "./styles.css";

interface IProps {
  videoUrl: string;
  setVideoUrl: Function;
}
const VideoPlayerModal: React.FC<IProps> = ({
  videoUrl,
  setVideoUrl,
}) => {
  const videoRef = useRef(null);

  return (
    <Modal
      className="entry-request-video-display"
      centered
      title={<h1>Reproducir video</h1>}
      open={videoUrl !== ""}
      footer={null}
      onCancel={() => {
        setVideoUrl("");
        if (videoRef != null && videoRef.current != null)
          videoRef.current.pause();
      }}
      zIndex={2100}
    >
      <video src={videoUrl} width="100%" controls ref={videoRef}></video>
    </Modal>
  );
};

export default VideoPlayerModal;
