import React, { FC, useState, useEffect, useRef } from "react";
import { RcFile } from 'antd/es/upload';
//Antd
import {
  Button,
  Image
} from "antd";
//Icons
import {
  CaretRightOutlined,
  PauseOutlined
} from "@ant-design/icons";
import "./styles.css";

const getBase64 = async (file: RcFile): Promise<string> =>
new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = error => reject(error);
});

interface FilePreview extends RcFile {
  source: string;
}

const AttachmentsMap = ({
  attached,
  setVideoUrl,
}: {
  attached: {
    file: RcFile;
  }[];
  setVideoUrl: Function;
}) => {
  const [previews, setPreviews] = useState<FilePreview[]>([]);

  useEffect(() => {
    const process = async (attached) => {
      const newPreviews = await Promise.all(attached.map(async (attachment) => {
        const source = await getBase64(attachment.file).then(response => response).catch(() => "");
        return {
          ...attachment,
          source
        };
      }));
      setPreviews(newPreviews);
    }
    process(attached);
  }, [attached]);
  
  const IsImage = (url: string) => {
    const fileTypes: any[] = ["png", "jpg", "jpeg"];
    const fileExtension = url?.split(".").pop();
    if (fileTypes.includes(fileExtension?.toLowerCase())) return true;
    return false;
  };

  const IsVideo = (url: string) => {
    const fileTypes: any[] = ["mp4", "avi", "flv", "wmv", "mov", "hvec"];
    const fileExtension = url?.split(".").pop();
    if (fileTypes.includes(fileExtension?.toLowerCase())) return true;
    return false;
  };

  const IsAudio = (url: string) => {
    const fileTypes: any[] = [
      "mp3",
      "ogg",
      "wav",
      "wave",
      "wma",
      "oga",
      "mogg",
      "aac",
      "m4a",
      "3gp",
      "m4r",
    ];
    const fileExtension = url?.split(".").pop();
    if (fileTypes.includes(fileExtension?.toLowerCase())) return true;
    return false;
  };

  const AudioAttachment: FC<{ url: string }> = ({ url }) => {
    const audioPlayer = useRef<HTMLAudioElement | null>(null);
    const [status, setStatus] = useState<"playing" | "paused" | "idle">("idle");
    const [durationTime, setDurationTime] = useState<string>("0:00");
    const [progress, setProgress] = useState<number>(0);
    const [currentTime, setCurrentTime] = useState<string>("0:00");

    const calculateProgress = () => {
      if (audioPlayer.current === null) return;
      if (
        audioPlayer.current.currentTime <= 0 ||
        audioPlayer.current.duration <= 0
      ) {
        setProgress(0);
      } else {
        setProgress(
          (audioPlayer.current.currentTime * 100) / audioPlayer.current.duration
        );
      }
      setCurrentTime(calculateTime(audioPlayer.current.currentTime));
    };

    const calculateTime = (secs: number) => {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`;
    };

    const displayDuration = () =>
      audioPlayer.current &&
      setDurationTime(calculateTime(audioPlayer.current.duration));

    const tooglePlay = () => {
      if (audioPlayer.current === null) return;
      // if we are playing, let's pause
      if (audioPlayer.current.paused === false) {
        audioPlayer.current.pause();
        setStatus("paused");
        // else, let's play
      } else {
        audioPlayer.current.play();
        setStatus("playing");
      }
    };

    useEffect(() => {
      audioPlayer.current &&
        audioPlayer.current.addEventListener("loadedmetadata", () =>
          displayDuration()
        );
      audioPlayer.current &&
        audioPlayer.current.addEventListener("ended", () => setStatus("idle"));
      audioPlayer.current &&
        audioPlayer.current.addEventListener("timeupdate", () =>
          calculateProgress()
        );
    }, []);

    return (
      <div className="attached-container-audio">
        <Button
          shape="round"
          type="ghost"
          icon={
            status === "playing" ? <PauseOutlined /> : <CaretRightOutlined />
          }
          onClick={tooglePlay}
        />
        <div className="duration-display">
          <span>{currentTime}</span>
          <div className="outer-display">
            <div className="inner-display" style={{ width: `${progress}%` }} />
          </div>
          <span>{durationTime}</span>
        </div>
        <audio controls src={`${url}`} ref={audioPlayer} />
      </div>
    );
  };

  if (previews.length === 0) return null;

  return (
    <div className="attachments-container">
      {previews.map((attachment, Aindex: number) =>
        <>
          {attachment.source !== "" && IsImage(attachment.name) && (
            <figure className="image-attachment">
              <Image
                src={`${attachment.source}`}
                alt={attachment.name}
                key={"img" + Aindex}
              />
            </figure>
          )}
          {attachment.source !== "" && IsVideo(attachment.name) && (
              <Button
                icon={<CaretRightOutlined />}
                onClick={() => {
                  setVideoUrl(`${attachment.source}`);
                }}
                className="video-attachment"
                key={"button" + Aindex}
              >
                {attachment.name}
              </Button>
          )}
          {attachment.source !== "" && IsAudio(attachment.name) && (
            <AudioAttachment url={attachment.source} key={"audio" + Aindex} />
          )}
        </>  
      )}
    </div>
  );
};

export default AttachmentsMap;
