import React, {
  useEffect,
  useState,
  useRef
} from "react";

import {
  Button,
  Input,
  Form,
  message,
  Upload,
  Popover,
  InputRef
} from "antd";

//WindowSize
import {
  gtmSetId
} from "../../../utilities";
import {
  CkPaperClipIcon,
  CkPaperPlaneIcon,
} from "../../../assets/SvgIcons";
import {
  PictureOutlined,
  VideoCameraOutlined,
  AudioOutlined
} from "@ant-design/icons";
import { TExpectedUserInput, userInputEvent, addInteractionLoadingEvent, removeInteractionLoadingEvent } from "..";
import { CkButton } from "../../../CkUI";

import "./styles.css";

const { TextArea } = Input;
const regexFullName = /^[a-zA-Z]+(?:\s+[a-zA-Z]+)+$/;
const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const regexPhoneNumber = /^\d{10}$/;

const UserInput = ({
  enableSend,
  expectedUserInput,
  setExpectedUserInput,
  fileList,
  setFileList,
  setAudioVisible,
  messageForm,
  user
}: {
  enableSend: boolean;
  expectedUserInput: TExpectedUserInput;
  setExpectedUserInput: Function;
  fileList: any[];
  setFileList: Function;
  setAudioVisible: Function;
  messageForm: any;
  user: any | undefined;
}) => {
  const textareaRef = useRef<InputRef>(null);
  const [value, setValue] = useState<string | number>("");
  const [validValue, setValidValue] = useState<boolean>(false);
  const [placeholder, setPlaceholder] = useState<string>("");
  const [interactionLoading, setInteractionLoading] = useState<boolean>(false);

  const fireEventChange = (value) => {
    const customEvent = new CustomEvent(userInputEvent, { detail: typeof value === "string" ? value.trim() : value });
    document.dispatchEvent(customEvent);
  };

  const handleInteractionLoadingEvent = (event) => {
    setInteractionLoading(event.detail === true);
  }

  useEffect(() => {
    addInteractionLoadingEvent(handleInteractionLoadingEvent);
    return () => {
      removeInteractionLoadingEvent(handleInteractionLoadingEvent);
    };
  }, []);

  useEffect(() => {
    var message = "";
    switch(expectedUserInput) {
      case "message":
        message = "Escribe tu mensaje";
        break;
      case "vehicle":
        message = "Escribe los datos de tu vehículo";
        break;
      case "name":
        message = "Escribe tu nombre";
        break;
      case "email":
        message = "Escribe tu correo electrónico";
        break;
      case "telephone":
        message = "Escribe teléfono celular";
        break;
    }
    
    setPlaceholder(message);
    setValue("");
    messageForm.resetFields();
    setValidValue(false);
  }, [expectedUserInput]);

  useEffect(() => {
    placeholder.length > 0 && textareaRef.current !== null && textareaRef.current.focus({
      cursor: "all",
      preventScroll: false
    });
  }, [placeholder]);

  const saveFilesLocally = async (
    onSuccess: Function,
    onError: Function,
    file: any
  ) => {
    try {
      const fileUploaded = {
        uid: fileList.length.toString(),
        name: file.name,
        status: "done",
        file: file,
      };
      if (fileList.length === 0) {
        setFileList([...fileList, fileUploaded]);
      } else {
        setFileList([...fileList, fileUploaded]);
      }
      onSuccess();
    } catch (error) {
      onError();
    }
  };

  const getFileDuration = async (file: any) => {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        //@ts-ignore
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadProps = {
    //fileList,
    name: "file",
    showUploadList: false,
    accept: "image/*, video/*, audio/*",
    beforeUpload: async (file: any) => {
      const videoDuration = user ? 15 : 5;
      if (!user && fileList?.length >= 2) {
        message.error({
          content: `Solo se permite adjuntar dos archivos.`,
          style: { zIndex: 3000 },
        });

        return false;
      }

      if (!file.type.startsWith("image/") && !file.type.startsWith("video/")) {
        message.error({
          content: `Solo se soportan archivos png, jpg, jpeg, audio y videos de hasta ${videoDuration} segundos y menores a 5 MB.`,
          style: { zIndex: 3000 },
        });
        return false;
      }

      if (file.type.startsWith("video/") || file.type.startsWith("audio/")) {
        const duration = await getFileDuration(file);
        if (
          //@ts-ignore
          parseInt(duration) >= videoDuration + 1 ||
          file.size > 1024 * 1024 * 5
        ) {
          message.error({
            content: `Solo se soportan archivos png, jpg, jpeg, audio y videos de hasta ${videoDuration} segundos y menores a 5 MB.`,
            style: { zIndex: 3000 },
          });
          return false;
        }
      }
      return file;
    },
    customRequest: ({ onSuccess, onError, file }: any) => {
      saveFilesLocally(onSuccess, onError, file);
    },
  };

  const validateForm = (e: any) => {
    
    switch(expectedUserInput) {
      case "vehicle":
      case "message":
        setValidValue(e.trim().length > 0 && e.trim().length <= 300);
        break;
      case "name":
        setValidValue(regexFullName.test(e) && e.trim().length <= 300);
        break;
      case "email":
        setValidValue(regexEmail.test(e) && e.trim().length <= 300);
        break;
      case "telephone":
        setValidValue(regexPhoneNumber.test(e));
        break;
    }
    setValue(e.trim());
  };

  const AttachmentsButtons = () => (
    <>
      <Upload
        {...uploadProps}
        fileList={fileList}
        accept="image/png, image/jpeg, image/jpg"
        multiple
        disabled={expectedUserInput != "message" || !!user === false && fileList.length >= 2}
      >
        <PictureOutlined
          id="CONSUL0010"
          size={22}
          style={{
            color:
            expectedUserInput !== "message" ? "GrayText" : "var(--primary-color)",
          }}
          onClick={(e) => gtmSetId(e.currentTarget)}
          
        />
      </Upload>

      <Upload
        {...uploadProps}
        fileList={fileList}
        accept="video/mp4, video/avi, video/flv, video/wmv, video/mov"
        multiple
        disabled={expectedUserInput != "message" || !!user === false && fileList.length >= 2}
      >
        <VideoCameraOutlined
          id="CONSUL011"
          size={22}
          style={{
            color:
            expectedUserInput !== "message" ? "GrayText" : "var(--primary-color)",
          }}
          disabled={expectedUserInput != "message"}
          onClick={(e) => gtmSetId(e.currentTarget)}
        />
      </Upload>

      <AudioOutlined
        id="CONSUL012"
        size={22}
        style={{
          color:
          expectedUserInput !== "message" ? "GrayText" : "var(--primary-color)",
        }}
        disabled={expectedUserInput != "message" || !!user === false && fileList.length >= 2}
        onClick={(e) => {
          setAudioVisible(true);
          gtmSetId(e.currentTarget);
        }}
      />
    </>
  );

  const handleKeyDown = (event) => validValue && event.altKey === false && event.ctrlKey === false && event.shiftKey === false && submit();

  const submit = () => {
    if (validValue === false) return;
    fireEventChange(value);
    setExpectedUserInput(undefined);
  }

  return (
    <div className="submit-messages">
      <Form className="no-padding" form={messageForm}>
        <Form.Item
          name="message"
          rules={[
            {
              required: true,
              message: "Este campo es obligatorio"
            },
            {
              max: 300,
              message: "Solo se admite un máximo de 300.",
            },
            ...(expectedUserInput === "name" ? [{
              pattern: regexFullName,
              message: "Debe ingresar un nombre y apellido válido",
            }] : []),
            ...(expectedUserInput === "email" ? [{
              pattern: regexEmail,
              message: "Debe ingresar un correo electrónico válido",
            }] : []),
            ...(expectedUserInput === "telephone" ? [{
              pattern: regexPhoneNumber,
              message: "Debe ingresar un teléfono celular válido",
            }] : [])
          ]}
        >
          <TextArea
            ref={textareaRef}
            disabled={expectedUserInput === undefined}
            value={value}
            onChange={(e) => validateForm(e.target.value)}
            autoSize={{ minRows: 1, maxRows: 3 }}
            placeholder={placeholder}
            onPressEnter={handleKeyDown}
            maxLength={300}
          />
        </Form.Item>
      </Form>
      <Popover
        content={!!user === false  && fileList.length >= 2 ? null : <AttachmentsButtons />}
        title={null}
        trigger="click"
        overlayClassName="attachments-popover"
        getPopupContainer={(trigger: any) => trigger.parentNode}
      >
        <Button 
          type="ghost"
          icon={<CkPaperClipIcon />} 
          disabled={expectedUserInput !== "message" || !!user === false && fileList.length >= 2}
        />
      </Popover>
      <Button
        id="CONSUL013"
        className="submit-btn"
        disabled={validValue === false || expectedUserInput === undefined}
        onClick={validValue === false ? () => {} : submit}
        icon={<CkPaperPlaneIcon />}
      />
      <div className={`send-button-wrapper animate__animated ${expectedUserInput === "terms" ? "animate__fadeIn active" : ""}`}>
        <CkButton
          disabled={enableSend === false}
          loading={interactionLoading} 
          onClick={() => fireEventChange(true)}>
          Enviar
        </CkButton>
      </div>
    </div>
  );
};

export default UserInput;
