import React, { useState, memo, FC } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import { Input, message, Form } from "antd";
import SignOnBenefitsMessage from "../../../components/Modals/SignOnBenefitsMessage";
import { IRequestResume } from "..";
import { AuthRest } from "../../../auth/SignInAuth";
import { gtmAddLayer } from "../../../utilities";
import { PromoCodesAPI } from "../../../services/axios";
import { CkModal, CkCheckbox, CkButton } from "../../../CkUI";
import "./styles.css";

const ReCaptcha = loadable(() => import("../../../components/ReCaptcha"));

interface IProps {
  setRequestResume: Function;
  requestResume: IRequestResume | undefined;
  onCancel: Function;
}
const CreateAccountCTA: FC<IProps> = ({
  setRequestResume,
  requestResume,
  onCancel,
}) => {
  /**
   * Constants
   */
  const authRest = new AuthRest();

  /**
   * States
   */
  const [displayCreateAccount, setDisplayCreateAccount] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [checkPassword, setCheckPassword] = useState<string>("");
  const [recaptchaValidation, setRecaptchaValidation] =
    useState<boolean>(false);
  const [check, setCheck] = useState<boolean>(false);
  const [signupForm] = Form.useForm();

  /**
   * Functions
   */
  const cancelSignUp = () => {
    onCancel && onCancel();
    setRequestResume(undefined);
  };

  const prepareForm = () => {
    setDisplayCreateAccount(true);
    signupForm.setFields([
      {
        name: "name",
        value: requestResume.endConsumer.name,
      },
      {
        name: "lastName",
        value: requestResume.endConsumer.lastName,
      },
      {
        name: "email",
        value: requestResume.endConsumer.email,
      },
    ]);
  };

  const signUp = async () => {
    // Let's validate the fields
    const formData = await signupForm
      .validateFields()
      .then((values) => values)
      .catch(() => false);

    // Is formData is equals to false, return false to prevent excecution.
    if (formData === false) return false;

    setLoading(true);

    // Perform signUp request
    const signupResponse = await authRest
      .signUp(
        {
          firstName: formData.name,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.password,
        },
        true
      )
      .then((response) => response);

    if (signupResponse.status === false) {
      message.error(signupResponse.message);
      setLoading(false);
    } else {
      // set user in context
      // TODO: Do something to prevent redirect to home to set user
      // setUser(signupResponse.data.user);

      // track login event, if available tracking
      gtmAddLayer({
        User_ID: signupResponse.data.user.id.toString(),
        account_type: "Customer",
        event: "user_register",
      });

      PromoCodesAPI.checkUserInvite({
        userInvitedID: parseInt(signupResponse.data.user.id),
        userInvitedEmail: signupResponse.data.user.email,
      })
        .then((result) => {})
        .catch((err) => {});

      // Close modal, drawer and clear form
      message.success("Creación de usuario exitosa, será redirigido al inicio");
      window.setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    }
  };

  if (requestResume === undefined) return null;

  return (
    <CkModal
      zIndex={3500}
      className="create-account-cta"
      open={requestResume !== undefined}
      maskClosable={false}
      footer={null}
      onCancel={cancelSignUp}
    >
      {displayCreateAccount === false && (
        <div
          className={`cta-display ${requestResume.loggedUser ? "padded" : ""}`}
        >
          <div className="upper">
            <h1>¡Mensaje enviado!</h1>
            <p className="success-message">
              <span>{`${requestResume.endConsumer.name} ${requestResume.endConsumer.lastName}`}</span>
              , muy pronto recibirás una respuesta sobre la{" "}
              {requestResume.contactType === "quotation"
                ? "estimación"
                : "duda"}{" "}
              enviada a <span>{requestResume.workshop.name}</span>
            </p>
          </div>
          {requestResume.loggedUser === false && (
            <>
              <SignOnBenefitsMessage />
              <div className="lower">
                <CkButton onClick={prepareForm}>
                  Crear cuenta
                </CkButton>
                <CkButton
                  type="link"
                  onClick={cancelSignUp}
                >
                  Continuar como invitado
                </CkButton>
              </div>
            </>
          )}
        </div>
      )}
      {displayCreateAccount === true && (
        <div className="cta-signup-form">
          <h1 className="title">Crear cuenta</h1>
          <Form form={signupForm}>
            <Form.Item
              className="--alter --no-asterisk"
              label={<span>Nombre(s)</span>}
              name={"name"}
              rules={[
                {
                  required: true,
                  message: "Este campo es obligatorio",
                },
              ]}
            >
              <Input
                className="--alter"
                placeholder="Escribe tu(s) nombre(s)"
                readOnly
                maxLength={50}
              />
            </Form.Item>
            <Form.Item
              className="--alter --no-asterisk"
              label={<span>Apellido(s)</span>}
              name={"lastName"}
              rules={[
                {
                  required: true,
                  message: "Este campo es obligatorio",
                },
              ]}
            >
              <Input
                className="--alter"
                placeholder="Escribe tu(s) apellido(s)"
                readOnly
                maxLength={50}
              />
            </Form.Item>
            <Form.Item
              className="--alter --no-asterisk"
              label={<span>Correo electrónico</span>}
              name={"email"}
              rules={[
                {
                  required: true,
                  message: "Este campo es obligatorio",
                  type: "email",
                },
              ]}
            >
              <Input
                type="email"
                className="--alter"
                placeholder="Escribe tu correo electrónico"
                readOnly
                maxLength={256}
              />
            </Form.Item>
            <Form.Item
              className="--alter --no-asterisk"
              name="password"
              label={<span>Contraseña</span>}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Ingresa la contraseña",
                },
                {
                  pattern:
                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-#?!@$%^.,;:&*+¿¡|°"()/={}\[\]]).{8,}$/,
                  message:
                    "Debe tener al menos 8 caracteres, minúsculas, mayúsculas, y por lo menos un número y un símbolo",
                },
              ]}
            >
              <Input.Password
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="--alter"
                placeholder="Escribe tu contraseña"
              />
            </Form.Item>
            {!password && (
              <p className="black-70" style={{ fontSize: "12px" }}>
                Debe tener al menos 8 caracteres, minúsculas, mayúsculas, y por
                lo menos un número y un símbolo
              </p>
            )}
            <Form.Item
              className="--alter --no-asterisk"
              name="checkPassword"
              label={<span>Comprueba tu contraseña</span>}
              hasFeedback
              validateStatus={`${
                checkPassword
                  ? checkPassword === password
                    ? "success"
                    : "error"
                  : ""
              }`}
              rules={[
                {
                  required: true,
                  message: "Ingresa de nuevo la contraseña",
                },
              ]}
            >
              <Input.Password
                className="--alter"
                onChange={(e) => setCheckPassword(e.target.value)}
                placeholder="Escribe de nuevo tu contraseña"
              />
            </Form.Item>
            <ReCaptcha
              styles={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "25px",
              }}
              setRecaptchaValidation={setRecaptchaValidation}
            />
            <CkCheckbox
              noMargin
              checkboxProps={{
                checked: check,
                onChange: (e) => setCheck(e.target.checked)
              }}
            >
              He leído y estoy de acuerdo con los
              <Link target="_blank" to="/terminosycondiciones">
                <span className="mobile-link"> T y C</span>
                <span className="desktop-link">Términos y Condiciones</span>
              </Link>
            </CkCheckbox>
            <CkButton
              className="sign-up-btn"
              disabled={
                !recaptchaValidation ||
                !check ||
                !password ||
                !checkPassword ||
                password !== checkPassword
              }
              size="large"
              onClick={signUp}
              loading={loading}
            >
              Crear cuenta
            </CkButton>
          </Form>
        </div>
      )}
    </CkModal>
  );
};

export default memo(CreateAccountCTA);
