import React from "react";
import { useIsMobileScreen } from "../../utilities";
import "./style.css";

const ClosureNoticePage = () => {
  const isMobile = useIsMobileScreen(992);
  return (
    <div className="closure-notice-page closure-banner-display" />
  )
}

export default ClosureNoticePage;
