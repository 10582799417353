import { NamedExoticComponent, ReactNode } from "react";
import Home from "./screens/Home";
import { loadableComponent } from "./utilities";
import { LoadableComponent } from "@loadable/component";
import ClosureNoticePage from "./screens/ClosureNoticePage";

/*
const ScheduleAppointmentPage = loadableComponent(
  () => import("./screens/ScheduleAppointmentPage"),
  {
    resolveComponent: (components) => components.ScheduleAppointmentPage,
  }
);
const CarkerInformationPage = loadableComponent(
  () => import("./screens/CarkerInformationPage"),
  {
    resolveComponent: (components) => components.CarkerInformationPage,
  }
);

const AffiliatedWorkshopsPage = loadableComponent(
  () => import("./screens/AffiliatedWorkshopsPage"),
  {
    resolveComponent: (components) => components.AffiliatedWorkshopsPage,
  }
);
// const Home = loadableComponent(() => import("./screens/Home"));
const ContactWorkshopPage = loadableComponent(
  () => import("./screens/ContactWorkshopPage")
);
const MyServices = loadableComponent(
  () => import("./screens/MobilContigo LandingPage/MyGarage/MyServices")
);
const MyServiceDetail = loadableComponent(
  () =>
    import(
      "./screens/MobilContigo LandingPage/MyGarage/MyServices/AppointmentDetail"
    )
);
const MyRatings = loadableComponent(
  () => import("./screens/MobilContigo LandingPage/MyGarage/MyRatings")
);
const Blog = loadableComponent(
  () => import("./screens/MobilContigo LandingPage/Blog")
);
const PostContent = loadableComponent(
  () => import("./screens/MobilContigo LandingPage/PostContent")
);
const ContactUs = loadableComponent(
  () => import("./screens/MobilContigo LandingPage/ContactUs")
);
const FrequentQuestionsPage = loadableComponent(
  () => import("./screens/MobilContigo LandingPage/FrequentQuestionsPage")
);
const CarkerRewards = loadableComponent(
  () => import("./screens/MobilContigo LandingPage/CarkerRewards")
);
const MyVehicles = loadableComponent(
  () => import("./screens/MobilContigo LandingPage/MyGarage/MyVehicles")
);
const MyRequests = loadableComponent(
  () => import("./screens/MobilContigo LandingPage/MyGarage/MyRequests")
);
const RateWorkshop = loadableComponent(
  () => import("./screens/MobilContigo LandingPage/RateWorkshop")
);
const ReceptionFormWS = loadableComponent(
  () => import("./components/Modals/Appointments/ReceptionFormModal")
);
const DiagnosticFormWS = loadableComponent(
  () => import("./components/Modals/Appointments/DiagnosticFormModal")
);
const GarageAddVehicle = loadableComponent(
  () => import("./screens/MobilContigo LandingPage/MyGarage/GarageAddVehicle")
);
const GarageEditVehicle = loadableComponent(
  () => import("./screens/MobilContigo LandingPage/MyGarage/GarageEditVehicle")
);
const WorkshopDetails = loadableComponent(
  () => import("./screens/Workshops/WorkshopDetails")
);
const WorkshopGallery = loadableComponent(
  () => import("./screens/Workshops/WorkshopDetails/WorkshopGallery")
);
const TermsAndConditionsPage = loadableComponent(
  () => import("./screens/TermsAndConditionsPage")
);
const Profile = loadableComponent(
  () => import("./screens/MobilContigo LandingPage/Profile")
);
const Disclaimer = loadableComponent(
  () => import("./screens/MobilContigo LandingPage/Disclaimer")
);
const MapView = loadableComponent(
  () => import("./screens/WorkshopSearch/MapView")
);
const WorkshopLocation = loadableComponent(
  () => import("./screens/Workshops/WorkshopLocation")
);
const WorkshopsByPromotion = loadableComponent(
  () => import("./screens/Promotions/WorkshopsByPromotion")
);
const WorkshopsList = loadableComponent(
  () => import("./screens/WorkshopSearch/WorkshopsList")
);
const Campaigns = loadableComponent(() => import("./screens/Campaigns"));
const EndConsumerRedirect = loadableComponent(
  () => import("./screens/EndConsumerRedirect")
);
const ConfirmUserRedirect = loadableComponent(
  () => import("./screens/ConfirmUserRedirect")
);
 */
const NotFoundPage = loadableComponent(() => import("./screens/NotFoundPage"));
// This function is currently unsued, thats way this routes are commented
/* 
const PromotionsList = loadableComponent(() => import("./screens/Promotions/PromotionsList"));
const PromotionDetail = loadableComponent(() => import("./screens/Promotions/PromotionDetail")); 
*/

interface IRoute {
  path: string;
  component: ReactNode | LoadableComponent<unknown> | NamedExoticComponent;
  exact: boolean;
  props?: any;
  redirect?: string;
  protected?: boolean;
}
/**
 * `routesConfig` is an array of route configurations used for setting up the application's routing.
 * Each object in the array represents a route with its corresponding component, path, and other route-specific options.
 * Params:
 * path: string - path url
 * component: ReactNode - component to be render
 * exact: boolean - if route must be exact
 * props: object - props to be passed to the component
 * redirect: string - if is setted, it will redirect to this route, instead of rendering a component
 * protected: boolean - if is true, a valid session will be required to render the component.
 *            If there route is protected, and there is now session, user will be redirected to 404 route.
 */
const routesConfig : IRoute[] = [
  {
    path: "/",
    // @ts-ignore
    component: ClosureNoticePage, // Home
    exact: true,
  },
  /*
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    path: "/campaña",
    component: Campaigns,
    exact: true,
  },
  {
    path: "/EndConsumerRedirect",
    component: EndConsumerRedirect,
    exact: true,
  },
  {
    path: "/ConfirmUser",
    component: ConfirmUserRedirect,
    exact: true,
  },
  {
    path: "/mapView",
    component: MapView,
    exact: true,
  },
  {
    path: "/map/ubicacion/:lat/:lng",
    component: WorkshopLocation,
    exact: true,
  },
  {
    path: ["/Workshop/:name/:id", "/Workshop/:name/:id/:rating"],
    component: WorkshopDetails,
    exact: true,
  },
  {
    path: "/gallery/:name/:id",
    component: WorkshopGallery,
    exact: true,
  },
  {
    path: "/blog/:page([0-9]{1,10})",
    component: Blog,
    exact: true,
  },
  {
    path: "/blog/:category([a-zA-Z-]{1,300})/:urlTitle([a-zA-Z0-9-]{1,300})",
    component: PostContent,
    exact: true,
  },
  {
    path: "/que-es-CarKer",
    exact: true,
    redirect: "/quienes-somos",
  },
  {
    path: "/compromisos-talleres",
    exact: true,
    redirect: "/quienes-somos",
  },
  {
    path: "/quienes-somos",
    component: CarkerInformationPage,
    exact: true,
  },
  {
    path: "/talleres-afiliados",
    component: AffiliatedWorkshopsPage,
    exact: true,
  },
  {
    path: "/contacto",
    component: ContactUs,
    exact: true,
  },
  */
  // This function is currently unsued, thats way this routes are commented
  /* 
  {
    path: '/promocion/:promotionId([0-9]{1,10})',
    component: PromotionDetail,
    exact: true,
  },
  {
    path: '/promociones',
    component: PromotionsList,
    exact: true,
  }, */
  /*
  {
    path: "/promocion/:promotionId([0-9]{1,10})/talleres",
    component: WorkshopsByPromotion,
    exact: true,
  },
  {
    path: "/talleres/:workshopsArray",
    component: WorkshopsList,
    exact: true,
  },

  {
    path: "/terminosycondiciones",
    component: TermsAndConditionsPage,
    exact: true,
  },
  {
    path: "/preguntas-frecuentes",
    component: FrequentQuestionsPage,
    exact: true,
  },
  {
    path: "/aviso-legal",
    component: Disclaimer,
    exact: true,
  },
  // TODO: Check profile
  {
    path: ["/perfil", "/profile"],
    component: Profile,
    exact: true,
    protected: true,
    props: {
      logout: true,
      setUser: true,
    },
  },
  {
    path: "/taller/:workshopId/agendar",
    component: ScheduleAppointmentPage,
    exact: true,
  },
  // TODO: Check contacto
  {
    path: "/taller/:workshopId/contacto",
    component: ContactWorkshopPage,
    exact: true,
    props: {
      displayAs: "page",
    },
  },
  {
    path: "/vehiculos",
    component: MyVehicles,
    exact: true,
    protected: true,
  },
  {
    path: "/rate/:appId",
    component: RateWorkshop,
    exact: true,
    protected: true,
  },
  {
    path: "/chats",
    component: MyRequests,
    exact: true,
    protected: true,
  },
  {
    path: "/citas/:vehicleId?",
    component: MyServices,
    exact: true,
    protected: true,
  },
  {
    path: "/cita/:id?",
    component: MyServiceDetail,
    exact: true,
  },
  {
    path: "/calificaciones",
    component: MyRatings,
    exact: true,
    protected: true,
  },
  {
    path: "/recepcion/:id",
    component: ReceptionFormWS,
    exact: true,
    protected: true,
  },
  {
    path: ["/diagnostico/:id", "/cotizacion/:id"],
    component: DiagnosticFormWS,
    exact: true,
    protected: true,
  },
  {
    path: "/vehiculos/agregar",
    component: GarageAddVehicle,
    exact: true,
    protected: true,
  },
  {
    path: "/vehiculos/editar/:vehicleId",
    component: GarageEditVehicle,
    exact: true,
    protected: true,
  },*/
  {
    path: "/404",
    component: NotFoundPage,
    exact: true,
  },
];

export default routesConfig;
